import { notification, Tag, Tooltip } from 'antd';
import styles from './styles.module.scss';
import { APP_CONSTANS } from 'shared/constants';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { JobContentStates } from './components/JobContentStates';
import { useUserPermissionGranted } from 'hooks/api/user/useUserPermissionGranted';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import generalStyles from 'shared/styles/styles.module.scss';
import clsx from 'clsx';
import { extLinkSvg } from 'shared/images/extLinkSvg';
import { isGuid } from 'shared/utils/isGuid';
import { tablesNames } from 'api/tablesNames';
import { useEffect } from 'react';

export const JobContent: React.FC<any> = ({
  isFormDisabled,
  objectType,
  jobCode,
  jobId,
  orderId,
  orderCode,
  orderStateId,
  orderPayout,
  isJobPaid,
  jobPayout,
  currency,
  quantityFiles,
  jobContent,
  orderPaymentType,
  orderPayMethod,
  orderPrice,
  orderCalcDataPrice,
  invoiceStatus,
  invoiceIdFromOrder,
  invoiceCodeFromOrder,
  invoiceObjectPaymentMethodId,
  accountId,
  tmplCode,
  purchaseId,
  purchaseCode,
  purchaseStateId,
  purchaseStateName,
  purchaseStateDescription,
  purchaseAmount,
  purchasePayedSum,
  purchasePayedFee,
  purchasePayedTotal,
  invoiceObjectExternalId,
  invoiceObjectMemo,
  invoiceObjectCode,
  invoiceObjectStateId,
  invoiceObjectStateName,
  invoiceObjectStateDescription,
  invoiceObjectAmount,
  invoiceObjectDiscount,
  invoiceObjectDiscountSum,
  invoiceObjectPayedSum,
  invoiceObjectPaymentType,
  invoiceObjectRefundSum,
  invoiceObjectAccountId,
  invoiceObjectAccountName,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [api, contextHolder] = notification.useNotification();
  const getAllData = true;

  const { data: headers } = useQuery({
    queryKey: [
      objectType === APP_CONSTANS.INVOICE
        ? tablesNames.InvoicesHeader
        : tablesNames.PurchasesHeader,
    ],
    meta: {
      token,
      getAllData,
    },
    queryFn: useGetData,
    enabled:
      objectType === APP_CONSTANS.PURCHASE ||
      objectType === APP_CONSTANS.INVOICE,
  });

  const { data: accountData } = useQuery({
    queryKey: [queryNames.ACCOUNTS_getAccountById, accountId],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?id=${accountId}&columns=name`,
    },
    queryFn: useGetData,
    enabled: !!accountId && isGuid.test(accountId),
  });

  const { data: invoiceStates } = useQuery({
    queryKey: [queryNames.InvoiceStates],
    meta: {
      token,
      getAllData,
      columnParams: ['id', 'name'],
    },
    queryFn: useGetData,
    enabled: !!invoiceStatus,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const {
    isLoading: isLoadingCard,
    isError: isErrorCard,
    data: card,
  } = useQuery({
    queryKey: [
      queryNames.PaymentMethodById,
      orderPayMethod || invoiceObjectPaymentMethodId,
    ],
    meta: {
      token,
      getAllData,
      paymentsApi: true,
      includedDeleted: true,
      columnParams: [
        'name',
        'status',
        'exp_month',
        'exp_year',
        'brand_code',
        'deleted_at',
      ],
      additionalSearchParams: `?id=${
        orderPayMethod || invoiceObjectPaymentMethodId
      }`,
    },
    queryFn: useGetData,
    enabled: !!orderPayMethod || !!invoiceObjectPaymentMethodId,
  });

  const isEditor = useUserPermissionGranted({
    enabledUserTypes: [APP_CONSTANS.USER_TYPES.EDITOR],
  });

  useEffect(() => {
    if (orderCalcDataPrice !== orderPrice && isFormDisabled === false) {
      api.warning({
        message: 'Price changed',
        description:
          'Account subscription/tax rate or complications price was changed. Order price will be changed after saving changes.',
      });
    }
  }, [api, isFormDisabled, orderCalcDataPrice, orderPrice]);

  const stateTag = (
    type: 'purchase' | 'invoice',
    size: 'small' | 'normal' = 'normal'
  ) => (
    <Tooltip
      overlayClassName={styles.tooltip}
      {...(type === APP_CONSTANS.PURCHASE && {
        title: purchaseStateDescription,
      })}
      {...(type === APP_CONSTANS.INVOICE && {
        title: invoiceObjectStateDescription,
      })}
    >
      <Tag
        className={clsx(
          type === APP_CONSTANS.PURCHASE &&
            generalStyles.purchaseOrInvoiceStatus,
          type === APP_CONSTANS.INVOICE &&
            generalStyles.purchaseOrInvoiceStatus,
          size === 'small' && styles.smallStatus
        )}
        {...(type === APP_CONSTANS.PURCHASE && {
          ['data-purchase-id']: purchaseStateId,
        })}
        {...(type === APP_CONSTANS.INVOICE && {
          ['data-invoice-id']: invoiceObjectStateId,
        })}
      >
        {type === APP_CONSTANS.PURCHASE && purchaseStateName}
        {type === APP_CONSTANS.INVOICE && invoiceObjectStateName}
      </Tag>
    </Tooltip>
  );

  const propertyLine = (propertyName: string, value: any) => (
    <div className={styles.propertyData}>
      <span className={styles.header__text}>
        {propertyName &&
          headers?.data?.columns.find(
            (e: { name: string }) => e.name === propertyName
          ).title}
        :{' '}
      </span>
      <span className={styles.header__value}>{value}</span>
    </div>
  );

  const cardDataLine = () => (
    <div>
      <span className={styles.header__text}>Card: </span>
      <span className={styles.header__value}>
        {isErrorCard ? 'Error loading card data' : ''}
        {isLoadingCard ? 'Loading card data...' : ''}
        {card?.data?.deleted_at ? <Tag color="error">Deleted</Tag> : ''}

        {card?.data && !isErrorCard && !isLoadingCard
          ? `${card?.data?.brand_code} ${card?.data?.name}, exp.${card?.data?.exp_month}/${card?.data?.exp_year}, ${card?.data?.status}`
          : ''}
      </span>
    </div>
  );

  return (
    <>
      {objectType === 'purchase' && (
        <>
          <div>
            <div className={styles.tooltipPurchase}>
              <span>
                <span className={styles.header__text}>
                  {
                    headers?.data?.columns.find(
                      (e: { name: string }) => e.name === 'code'
                    ).title
                  }
                  :{' '}
                </span>
                <span className={styles.header__value}>{purchaseCode}</span>
              </span>
              {stateTag('purchase')}
            </div>
          </div>
          <div>
            <span className={styles.header__text}>
              {
                headers?.data?.columns.find(
                  (e: { name: string }) => e.name === 'amount'
                ).title
              }
              :{' '}
            </span>
            <span className={styles.header__value}>
              {renderMoneyWithCurrency(purchaseAmount, currency)}
            </span>
          </div>
          <div>
            <span className={styles.header__text}>
              {
                headers?.data?.columns.find(
                  (e: { name: string }) => e.name === 'payed_sum'
                ).title
              }
              :{' '}
            </span>
            <span className={styles.header__value}>
              {renderMoneyWithCurrency(purchasePayedSum, currency)}
            </span>
          </div>
          <div>
            <span className={styles.header__text}>
              {
                headers?.data?.columns.find(
                  (e: { name: string }) => e.name === 'payed_fee'
                ).title
              }
              :{' '}
            </span>
            <span className={styles.header__value}>
              {renderMoneyWithCurrency(purchasePayedFee, currency)}
            </span>
          </div>
          <div>
            <span className={styles.header__text}>
              {
                headers?.data?.columns.find(
                  (e: { name: string }) => e.name === 'payed_total'
                ).title
              }
              :{' '}
            </span>
            <span className={styles.header__value}>
              {renderMoneyWithCurrency(purchasePayedTotal, currency)}
            </span>
          </div>
          <div>
            <span className={styles.header__text}>Photographer Account: </span>
            <Link to={`/accounts/all/${accountId}`}>
              {accountData?.data?.name}
            </Link>
          </div>
        </>
      )}
      {objectType === 'invoice' && (
        <>
          <div>
            <div className={styles.tooltipPurchase}>
              <span>
                <span className={styles.header__text}>
                  {
                    headers?.data?.columns.find(
                      (e: { name: string }) => e.name === 'code'
                    ).title
                  }
                  :{' '}
                </span>
                <span className={styles.header__value}>
                  {invoiceObjectCode}
                </span>
              </span>
              {stateTag('invoice')}
            </div>
          </div>
          {propertyLine('payment_type', invoiceObjectPaymentType)}
          {typeof invoiceObjectPaymentType === 'string' &&
            invoiceObjectPaymentType.toLowerCase() === 'card' &&
            cardDataLine()}
          <div className={styles.amountsOfInvoice}>
            {propertyLine(
              'amount',
              renderMoneyWithCurrency(invoiceObjectAmount, currency)
            )}
            {propertyLine(
              'discount',
              renderMoneyWithCurrency(invoiceObjectDiscount, currency)
            )}
            {propertyLine(
              'discount_sum',
              renderMoneyWithCurrency(invoiceObjectDiscountSum, currency)
            )}
            {propertyLine(
              'payed_sum',
              renderMoneyWithCurrency(invoiceObjectPayedSum, currency)
            )}
            {propertyLine(
              'refund_sum',
              renderMoneyWithCurrency(invoiceObjectRefundSum, currency)
            )}
          </div>
          {propertyLine('memo', invoiceObjectMemo)}
          {propertyLine('external_id', invoiceObjectExternalId)}
          {invoiceObjectAccountName && invoiceObjectAccountId && (
            <div>
              <span className={styles.header__text}>Customer Account: </span>
              <Link to={`/accounts/all/${invoiceObjectAccountId}`}>
                {invoiceObjectAccountName}
              </Link>
            </div>
          )}
        </>
      )}
      <div className={styles.header__info}>
        {objectType === 'job' && (
          <div>
            <span className={styles.header__text}>Job ID: </span>
            <span className={styles.header__value} data-e2e="jobCode">
              {jobCode}
            </span>
            {!isEditor && (
              <Tooltip
                overlayClassName={styles.tooltip}
                title="View as a Photographer"
              >
                <a
                  href={`${APP_CONSTANS.BASE_APP_REACT}?type=job&id=${jobId}&auth_token=${token}`}
                  target="_blank"
                >
                  {extLinkSvg()}
                </a>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className={styles.header__info}>
        {objectType === 'job' && (
          <div>
            <span className={styles.header__text}>Purchase ID: </span>
            {purchaseId ? (
              <>
                <span className={styles.header__value}>
                  <Link to={`/purchases/all/${purchaseId}`}>
                    {purchaseCode}
                  </Link>
                </span>
                {stateTag('purchase', 'small')}
              </>
            ) : (
              <span className={styles.header__value}>Not exist</span>
            )}
          </div>
        )}
      </div>
      <div className={styles.header__info}>
        <div>
          {objectType === 'tmpl' && (
            <div>
              <span className={styles.header__text}>Template ID: </span>
              <span className={styles.header__value}>{tmplCode}</span>
            </div>
          )}
          {(objectType === 'job' || objectType === 'order') && (
            <div>
              <span className={styles.header__text}>Order ID: </span>
              <span className={styles.header__value} data-e2e="orderCode">
                {jobId ? (
                  <Link to={`/orders/all/${orderId}`}>{orderCode}</Link>
                ) : (
                  orderCode
                )}
              </span>
            </div>
          )}
          {!isEditor && (objectType === 'job' || objectType === 'order') && (
            <Tooltip
              overlayClassName={styles.tooltip}
              title="View as a Customer"
            >
              <a
                href={`${APP_CONSTANS.BASE_OLD_ADMIN_URL}orders/${orderId}/customer`}
                className={styles.extLink}
                target="_blank"
              >
                {extLinkSvg()}
              </a>
            </Tooltip>
          )}
          {objectType === 'job' && (
            <Tag
              className={clsx(generalStyles.orderStatus, styles.smallStatus)}
              data-order-status={orderStateId}
            >
              {APP_CONSTANS.ORDER_STATUSES_BY_GUID[orderStateId]}
            </Tag>
          )}
        </div>
      </div>

      <div className={styles.header__info}>
        {objectType === 'job' && (
          <div>
            <span className={styles.header__text}>Fulfilment Payout: </span>
            <span className={styles.header__value}>
              {renderMoneyWithCurrency(orderPayout, currency)}
            </span>
          </div>
        )}
        {objectType === 'order' && (
          <>
            {contextHolder}
            <div>
              <span className={styles.header__text}>Payment type: </span>
              <span className={styles.header__value}>{orderPaymentType}</span>
            </div>

            <div>
              <span className={styles.header__text}>Price: </span>
              <span
                className={clsx(
                  styles.header__value,
                  orderCalcDataPrice &&
                    orderCalcDataPrice !== orderPrice &&
                    isFormDisabled === false &&
                    styles.updatedPrice
                )}
              >
                {renderMoneyWithCurrency(
                  isFormDisabled === true ? orderPrice : orderCalcDataPrice,
                  currency
                )}
              </span>
            </div>

            <div>
              <span className={styles.header__text}>Fulfilment Payout: </span>
              <span className={styles.header__value}>
                {renderMoneyWithCurrency(orderPayout, currency)}
              </span>
            </div>
          </>
        )}

        <div>
          {objectType === 'job' && isJobPaid && (
            <>
              <span className={styles.header__text} data-payout-status="paid">
                Paid:
              </span>
              <span className={styles.header__value}>
                {renderMoneyWithCurrency(jobPayout, currency)}
              </span>
            </>
          )}
          {objectType === 'job' && !isJobPaid && (
            <span className={styles.header__text} data-payout-status="notPaid">
              Not Paid
            </span>
          )}
        </div>
      </div>
      {typeof orderPaymentType === 'string' &&
        orderPaymentType.toLowerCase() === 'card' &&
        cardDataLine()}

      {objectType === 'order' && (
        <div className={styles.header__info}>
          {invoiceIdFromOrder && (
            <div>
              <span className={styles.header__text}>Invoice Code: </span>
              <Link to={`/invoices/all/${invoiceIdFromOrder}`}>
                {invoiceCodeFromOrder}
              </Link>
            </div>
          )}

          {invoiceStatus && (
            <div>
              <span className={styles.header__text}>Invoice Status: </span>
              <span className={styles.header__value}>
                {
                  invoiceStates?.data?.find(
                    (state: { id: string; name: string }) =>
                      state.id === invoiceStatus
                  )?.name
                }
              </span>
            </div>
          )}
        </div>
      )}

      {objectType === 'purchase' || objectType === 'invoice' ? (
        <></>
      ) : (
        <>
          <div className={styles.header__info} style={{ columnGap: '10px' }}>
            <div>
              <span className={styles.header__text}>Required quantity: </span>
              <span className={styles.header__value}>{quantityFiles}</span>
            </div>

            {objectType === 'job' && (
              <JobContentStates
                contents={jobContent}
                quantityFiles={quantityFiles}
              />
            )}
          </div>

          <div>
            <span className={styles.header__text}>Customer Account: </span>
            <Link to={`/accounts/all/${accountId}`}>
              {accountData?.data?.name}
            </Link>
          </div>
        </>
      )}
    </>
  );
};
