import { Tag, Tooltip, message } from 'antd';
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Table from '../Table';
import { ContentTableT } from './types';
import globalStyles from '../../../shared/styles/styles.module.scss';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { ModalContent } from 'components/modals/ModalContent';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';
import { ContentImg } from '../ContentItem/components/ContentImg';
import { useNavigate, useParams } from 'react-router';
import { APP_PATH } from 'features/Routes/pathes';
import { columns } from './columns';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { isGuid } from 'shared/utils/isGuid';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';

export const ContentTable: FunctionComponent<ContentTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  toolBarRender = null,
  actionsColumn = null,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'contentTable',
  rowSelection = null,
  rowSelectionType = null,
  customClassName = null,
  refetchData = false,
  setRefetchData = null,
  refetchContentJob = null,
  commentSection,
  isCarousel = true,
  getIsDeleted = () => {},
  contentIds,
  ...rest
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [contentId, setContentId] = useState<string[]>([]);
  const [commentSectionTable, setCommentSectionTable] = useState<any>({});
  const [currentSliderIndex, setCurrentSliderIndex] = useState<number | null>(
    null
  );
  const currentURL = window.location.pathname;
  const token = useSelector(
    (state: any) => state.auth.token,
    (prev, next) => prev === next
  );
  const getAllData = true;
  const navigate = useNavigate();
  const params = useParams();
  const sysPrefIds = [
    '6528d584-5dff-43a4-8d55-1fdcab1abd86',
    '061c914a-e87a-49a6-a867-c07a91365beb',
    'b062b821-076d-4939-ad85-acb941250356',
    'd6b8f53c-e73d-4243-b27a-0cd16f4873b2',
  ];
  const notAcceptableMsg = `The photo doesn't meet minimum requirements.`;
  const { id } = params;

  // System Preferences
  const { data: dataSysPref } = useQuery({
    queryKey: [queryNames.Preferences],
    meta: {
      token,
      getAllData,
      columnParams: ['value', 'pref_code'],
      additionalSearchParams: `?scopes=system&ids=${sysPrefIds.join('&ids=')}`,
    },
    queryFn: useGetData,
  });

  const sysPreferences = useMemo(() => {
    const sysDataObj: { [key: string]: any } = {};
    dataSysPref?.data?.forEach(
      (sysData: { pref_code: string; value: string }) => {
        sysDataObj[sysData.pref_code] = sysData.value ? sysData.value : 0;
      }
    );
    return sysDataObj;
  }, [dataSysPref]);

  const handleAcceptableRange = (content: any) => {
    const { MaxFileSize, MaxPixels, MinFileSize, MinPixels } = sysPreferences;

    const fileSize = content?.content_size
      ? (content?.content_size / 1000000).toFixed(1)
      : 0;
    const fileRes = content?.['meta.resolution']
      ? content?.['meta.resolution'].replace(' MP', '')
      : 0;

    const isAcceptableSize =
      +fileSize >= +MinFileSize &&
      (+MaxFileSize ? +fileSize <= +MaxFileSize : true);
    const isAcceptableRes = content?.content_type.includes('video')
      ? true
      : +fileRes >= +MinPixels && (+MaxPixels ? +fileRes <= +MaxPixels : true);

    return isAcceptableSize && isAcceptableRes;
  };

  const handleContentClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, record: any) => {
      event.stopPropagation();
      setShowModal(true);
      if (currentURL.includes('fulfilment_contents')) {
        navigate(`${record.id}`, { replace: true });
        setCommentSectionTable({
          photographerId: record['user_id'],
          customerId: record['order.user.id'],
          internal: true,
          jobId: record['id'],
          orderId: record['order_id'],
        });
      } else {
        setContentId([record.id]);
        if (Array.isArray(contentIds) && contentIds.length) {
          setCurrentSliderIndex(
            contentIds.findIndex((e: string[]) => e === record.id)
          );
        }
      }
    },
    [currentURL, navigate, contentIds]
  );

  const renderLinkContentId = useCallback(
    (value: string, record: any) => {
      const isApple = record?.original_name?.startsWith('CAM-I-');
      const isAndroid = record?.original_name?.startsWith('CAM-A-');
      return (
        <a onClick={(event) => handleContentClick(event, record)}>
          {isApple && (
            <AppleOutlined style={{ marginRight: '6px', color: '#3f505a' }} />
          )}
          {isAndroid && (
            <AndroidOutlined style={{ marginRight: '6px', color: '#3f505a' }} />
          )}
          {value}
        </a>
      );
    },
    [handleContentClick]
  );

  const renderKB = useCallback(
    (value: number) => {
      let sysDataObj: { [key: string]: any } = {};
      dataSysPref?.data?.forEach(
        (sysData: { pref_code: string; value: string }) => {
          sysDataObj[sysData.pref_code] = sysData.value ? sysData.value : 0;
        }
      );
      const { MaxFileSize, MinFileSize } = sysDataObj;
      const fileSize = value ? (value / 1000000).toFixed(1) : 0;
      const isAcceptableSize =
        +fileSize >= +MinFileSize &&
        (+MaxFileSize ? +fileSize <= +MaxFileSize : true);
      return (
        <Tooltip
          title={notAcceptableMsg}
          placement="top"
          zIndex={isAcceptableSize === false ? 5 : -1}
          className={clsx(isAcceptableSize === false ? styles.underline : '')}
        >
          <span>{fileSize} MB</span>
        </Tooltip>
      );
    },
    [dataSysPref, notAcceptableMsg]
  );

  const renderRes = useCallback(
    (value: string) => {
      let sysDataObj: any = {};
      dataSysPref?.data?.forEach(
        (sysData: { pref_code: string; value: string }) => {
          sysDataObj[sysData.pref_code] = sysData.value ? sysData.value : 0;
        }
      );
      const { MaxPixels, MinPixels } = sysDataObj;
      const fileRes = typeof value === 'string' ? value.replace(' MP', '') : 0;
      const isAcceptableRes =
        +fileRes >= +MinPixels && (+MaxPixels ? +fileRes <= +MaxPixels : true);
      return (
        <Tooltip
          title={notAcceptableMsg}
          placement="top"
          zIndex={isAcceptableRes === false ? 5 : -1}
          className={clsx(isAcceptableRes === false ? styles.underline : '')}
        >
          <span>{fileRes}</span>
        </Tooltip>
      );
    },
    [dataSysPref, notAcceptableMsg]
  );

  const renderContentState = useCallback((value: string, record: any) => {
    const statusName = record?.['state.name'];
    return (
      <Tag
        className={globalStyles.contentStatus}
        data-content-status={record?.deleted_at ? 'Deleted' : value}
      >
        {record?.deleted_at ? 'Deleted' : statusName}
      </Tag>
    );
  }, []);

  const renderJobState = useCallback((value: string, record: any) => {
    const statusName = record?.['fulfilment.state.name'];
    return (
      <Tag
        className={globalStyles.contentStatus}
        data-job-status={record?.deleted_at ? 'Deleted' : value}
      >
        {statusName}
      </Tag>
    );
  }, []);

  const MemoizedContentImg = memo(ContentImg, (prevProps, nextProps) => {
    return (
      prevProps.previewUrl === nextProps.previewUrl &&
      prevProps.updatedAt === nextProps.updatedAt &&
      prevProps.id === nextProps.id
    );
  });

  const renderPreviewUrl = useCallback(
    (value: string, updated: number | string, contentItemId: string) => {
      return (
        <MemoizedContentImg
          previewUrl={value}
          updatedAt={updated}
          id={contentItemId}
          key={`${contentItemId}`}
        />
      );
    },
    [MemoizedContentImg]
  );

  const tableData = useCallback(
    (value: any, record: any) => {
      const fulfilmentId = record?.fulfilment_id;
      const orderId = record?.order_id;
      const userId = record?.['user_id'];

      return [
        {
          name: 'content_filename',
          action: renderLinkContentId(value, record),
        },
        {
          name: 'state.id',
          action: renderContentState(value, record),
        },
        {
          name: 'fulfilment.state_id',
          action: renderJobState(value, record),
        },
        {
          name: 'preview_url',
          action: renderPreviewUrl(value, record?.updated_at, record?.id),
        },
        {
          name: 'fulfilment.code',
          action: renderLink(value, `/fulfilments/all/${fulfilmentId}`),
        },
        {
          name: 'order.code',
          action: renderLink(value, `/orders/all/${orderId}`),
        },
        {
          name: 'content_size',
          action: renderKB(value),
        },
        {
          name: 'user.first_name',
          action: renderLink(value, `/users/all/${userId}`),
        },
        {
          name: 'user.last_name',
          action: renderLink(value, `/users/all/${userId}`),
        },
        {
          name: 'meta.resolution',
          action: renderRes(value),
        },
      ];
    },
    [
      renderLinkContentId,
      renderContentState,
      renderJobState,
      renderPreviewUrl,
      renderKB,
      renderRes,
    ]
  );

  useEffect(() => {
    if (currentURL.includes('fulfilment_contents') && id && isGuid.test(id)) {
      setContentId([id]);
      if (!showModal) {
        setShowModal(true);
      }
    }
    if (currentURL.includes('fulfilment_contents') && id && !isGuid.test(id)) {
      window.location.pathname = `/${APP_PATH.CONTENTS}`;
      message.error(`Not avialable content by id - ${id}`);
    }
  }, [currentURL, id, showModal]);

  return (
    <>
      <Table
        rowClassName={(record) =>
          handleAcceptableRange(record) ? '' : styles.notAcceptable
        }
        tableIndicatorClassName={clsx(tableIndicatorClassName)}
        customClassName={customClassName}
        searchingByAllTable={false}
        rowSelection={rowSelection}
        rowSelectionType={rowSelectionType}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        toolBarRender={toolBarRender}
        headerName={headerName}
        renderDataColumn={tableData}
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        refetchData={refetchData}
        setRefetchData={setRefetchData}
        quantityTitleText="contents"
        getIsDeleted={getIsDeleted}
        defaultSorter={[
          {
            field: 'cont.taken_at',
            order: 'descend',
          },
          {
            field: 'cont.original_name',
            order: 'ascend',
          },
        ]}
        columnsForRequest={columns}
        {...rest}
      />

      {showModal && (
        <ModalContent
          visible={showModal}
          setVisible={setShowModal}
          contentIds={contentIds || contentId}
          sliderIndex={currentSliderIndex || 0}
          setContentId={setContentId}
          setSliderIndex={setCurrentSliderIndex}
          setRefetchTableViewData={setRefetchData}
          commentSection={commentSection ? commentSection : commentSectionTable}
          isCarousel={isCarousel}
          refetchContentJob={refetchContentJob || null}
        />
      )}
    </>
  );
};
