import {
  CheckOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  CopyOutlined,
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Popover, Switch, Tag, Tooltip, message } from 'antd';
import { usePostData } from 'api/usePostData';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { APP_CONSTANS } from 'shared/constants';
import globalStyles from 'shared/styles/styles.module.scss';
import { copyTextToTheClipboard } from 'shared/utils/copyTextToTheClipboard';
import styles from './styles.module.scss';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';

export const ModalContentTitle: React.FC<any> = ({
  content,
  closeModal,
  refetchContent,
  refetchContentJob,
  refetchContentById,
  setRefetchData,
  isActions,
  setReplaceInModal,
  currentIndex,
  countOfFiles,
  sliderIndex,
  setSliderIndex,
  isCarousel,
  refetchContentBarView,
  setRefetchTableViewData,
  isHighQuality,
  setIsHighQuality,
}) => {
  const queryClient = useQueryClient();

  const token = useSelector((state: any) => state.auth.token);
  const [contentState, setContentState] = useState<string>('');
  const [contentReplacedId, setContentReplacedId] = useState<
    string | undefined
  >('');
  const replacingFileInput = useRef<any>(null);
  const isDeleted = content?.deleted_at;
  const contentUrl = content?.['content_url'];
  const originalUrl = content?.['original_url'];
  const releaseUrl = content?.['release_url'];

  useEffect(() => {
    if (content?.hasOwnProperty('state')) {
      setContentState(content?.state.id);
    } else if (content?.hasOwnProperty('state_id')) {
      setContentState(content?.state_id);
    } else {
      setContentState(content?.['state.id']);
    }
  }, [content]);

  const prevImg = () => {
    if (sliderIndex !== 0) {
      setSliderIndex((prevSliderIndex: any) => prevSliderIndex - 1);
    } else {
      closeModal();
    }
    setIsHighQuality(false);
  };

  const nextImg = () => {
    const lastIndexImg = countOfFiles - 1;
    if (sliderIndex !== lastIndexImg) {
      setSliderIndex((prevSliderIndex: any) => prevSliderIndex + 1);
    } else {
      closeModal();
    }
    setIsHighQuality(false);
  };

  const {
    mutate,
    isLoading,
    variables: mutateVariables,
  } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Saving data error. Network Error');
    },
    onSuccess: (data, variables) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
      } else {
        if (variables?.url?.includes('replace')) {
          message.success('Content was replaced');
          queryClient.resetQueries({
            predicate: (query: any) =>
              query?.queryKey[1]?.id === contentReplacedId,
          });
        } else {
          message.success('Content status was changed!');
          setReplaceInModal(true);
          if (isCarousel) {
            nextImg();
          }
        }
        if (refetchContent) {
          refetchContent();
        }
        if (refetchContentJob) {
          refetchContentJob();
        }
        if (refetchContentById) {
          refetchContentById();
        }
        if (setRefetchData) {
          setRefetchData(true);
        }
      }
      if (refetchContentBarView) {
        refetchContentBarView();
      }
      if (setRefetchTableViewData) {
        setRefetchTableViewData(true);
      }
    },
  });

  const changeStatus = (stateName: string, option?: number) => {
    const isReject: boolean = stateName === 'REJECTED_CONTENT';

    const submittedData: {
      id: string;
      option?: number;
      state_id?: string;
    } = {
      id: content?.id,
    };

    if (isReject) {
      submittedData.option = option;
    } else {
      submittedData.state_id =
        APP_CONSTANS.FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE[stateName] || '';
    }

    mutate({
      data: submittedData,
      token,
      otherProps: isReject ? 'engine/reject_content' : 'fulfilment_contents',
      method: isReject ? 'POST' : 'PUT',
    });
  };

  const replaceCallInput = (id: string) => {
    setContentReplacedId(id);
    replacingFileInput.current.click();
  };

  const replaceFile = (event: any) => {
    event.preventDefault();
    const newFile: any = replacingFileInput.current.files[0];
    const formData = new FormData();
    formData.append('file', newFile);
    mutate({
      data: {},
      formData: formData,
      token,
      url: `${APP_CONSTANS.BASE_FILES_URL}/replace/content/${contentReplacedId}`,
      method: 'POST',
    });
  };

  const switchOnChange = (checked: boolean) => {
    setIsHighQuality(checked);
  };

  return (
    <div className={styles.header}>
      <div className={styles.header__info}>
        <div className={styles.header__icons}>
          <Tooltip title="Copy preview link" placement="bottom">
            <Button
              onClick={(e) => {
                if (contentUrl) void copyTextToTheClipboard(e, contentUrl);
              }}
              type="text"
              icon={<CopyOutlined disabled={!contentUrl} />}
              disabled={false}
            />
          </Tooltip>
          <Popover
            content={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {originalUrl && (
                  <Button
                    disabled={false}
                    download
                    href={originalUrl}
                    type="text"
                  >
                    Original file
                  </Button>
                )}
                {releaseUrl && (
                  <Button
                    disabled={false}
                    download
                    href={releaseUrl}
                    type="text"
                  >
                    Release file
                  </Button>
                )}
                {contentUrl && (
                  <Button
                    disabled={false}
                    download
                    href={contentUrl}
                    type="text"
                  >
                    Preview file
                  </Button>
                )}
              </div>
            }
            trigger="click"
          >
            <Button disabled={false} type="text" icon={<DownloadOutlined />} />
          </Popover>
          {isActions && (
            <Tooltip title="Replace" placement="bottom">
              {isLoading && mutateVariables?.url?.includes('replace') ? (
                <Button type="text" loading disabled />
              ) : (
                <Button
                  onClick={() => replaceCallInput(content?.id)}
                  type="text"
                  icon={<CloudUploadOutlined />}
                  disabled={false}
                />
              )}
            </Tooltip>
          )}
        </div>

        <div className={styles.header__options}>
          <p>
            <span className={styles.header__name}>
              {content?.content_filename}
            </span>
          </p>
          <div className={styles.header__exif}>
            {content?.meta?.is_video ? (
              <>
                <p>
                  <span className={styles.header__label}>
                    Length of video:{' '}
                  </span>
                  <span>{content?.meta?.duration || '-'}</span>
                </p>
                <p>
                  <span className={styles.header__label}>Size: </span>
                  <span>
                    {content?.content_size
                      ? (content?.content_size / 1000000).toFixed(1)
                      : 0}{' '}
                    MB
                  </span>
                </p>
                <p>
                  <span className={styles.header__label}>DIM: </span>
                  <span>{content?.meta?.full_size || '-'}</span>
                </p>
                <p>
                  <span className={styles.header__label}>Res: </span>
                  <span>{content?.meta?.resolution || '-'}</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  <span className={styles.header__label}>Res: </span>
                  <span>{content?.meta?.resolution || '-'}</span>
                </p>
                <p>
                  <span className={styles.header__label}>Size: </span>
                  <span>
                    {content?.content_size
                      ? (content?.content_size / 1000000).toFixed(1)
                      : 0}{' '}
                    MB
                  </span>
                </p>
                <p>
                  <span className={styles.header__label}>ISO: </span>
                  <span>{content?.meta?.iso_speed || '-'}</span>
                </p>
                <p>
                  <span className={styles.header__label}>Shtr: </span>
                  <span>{content?.meta?.shutter || '-'}</span>
                </p>
                <p>
                  <span className={styles.header__label}>Dms: </span>
                  <span>{content?.meta?.full_size || '-'}</span>
                </p>
              </>
            )}
            <p style={{ width: '100%' }}>
              <span className={styles.header__label}>Date: </span>
              <span>
                {content?.hasOwnProperty('taken_at')
                  ? convertUNIXToLocale(
                      content?.taken_at,
                      null,
                      content?.order?.timezone
                    )
                  : '-'}
              </span>
            </p>
          </div>
          <Tag
            className={globalStyles.contentStatus}
            data-content-status={isDeleted ? 'Deleted' : content?.state?.id}
          >
            {isDeleted ? 'Deleted' : content?.state?.name}
          </Tag>
        </div>
      </div>

      <div className={styles.header__actions}>
        <Switch
          checked={isHighQuality}
          checkedChildren="high"
          unCheckedChildren="low"
          onChange={switchOnChange}
          style={{ backgroundColor: isHighQuality ? '#fc594c' : '#70838E' }}
          disabled={false}
        />

        {isCarousel && (
          <div className={styles.header__arrow}>
            <Button
              className={styles.header__arrow_prev}
              icon={<LeftOutlined />}
              onClick={prevImg}
              disabled={false}
            ></Button>
            <span
              className={styles.header__arrow_number}
            >{`${currentIndex} / ${countOfFiles}`}</span>
            <Button
              className={styles.header__arrow_next}
              icon={<RightOutlined />}
              onClick={nextImg}
              disabled={false}
            ></Button>
          </div>
        )}

        {isActions && (
          <>
            <Popover
              rootClassName={styles.popoverReject}
              content={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    disabled={
                      contentState === undefined ||
                      contentState ===
                        APP_CONSTANS.FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE
                          .REJECTED_CONTENT
                    }
                    onClick={() => changeStatus('REJECTED_CONTENT', 0)}
                    type="text"
                  >
                    Reject
                  </Button>
                  <Button
                    disabled={
                      contentState === undefined ||
                      contentState ===
                        APP_CONSTANS.FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE
                          .DUPLICATED_CONTENT
                    }
                    onClick={() => changeStatus('REJECTED_CONTENT', 1)}
                    type="text"
                  >
                    Duplicate
                  </Button>
                  <Button
                    disabled={
                      contentState === undefined ||
                      contentState ===
                        APP_CONSTANS.FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE
                          .OUT_OF_FOCUS_CONTENT
                    }
                    onClick={() => changeStatus('REJECTED_CONTENT', 2)}
                    type="text"
                  >
                    Out of focus
                  </Button>
                </div>
              }
              trigger="click"
            >
              <Button
                disabled={contentState === undefined || content?.deleted_at}
                type="primary"
                icon={<CloseOutlined />}
                danger
              >
                Reject
              </Button>
            </Popover>
            <Button
              className={clsx(globalStyles.btn, globalStyles.success__btn)}
              icon={<CheckOutlined />}
              onClick={() => changeStatus('APPROVED_CONTENT')}
              disabled={
                contentState === undefined ||
                contentState ===
                  APP_CONSTANS.FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE
                    .APPROVED_CONTENT ||
                contentState ===
                  APP_CONSTANS.FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE
                    .DELIVERED_CONTENT
              }
            >
              Approve
            </Button>
          </>
        )}

        <input
          type="file"
          ref={replacingFileInput}
          onChange={replaceFile}
          className={styles.dNone}
        />

        <Button
          type="text"
          onClick={closeModal}
          className={styles.header__closeIcon}
          icon={<CloseOutlined />}
          disabled={false}
        />
      </div>
    </div>
  );
};
