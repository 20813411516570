export const columns = [
  'code',
  'id',
  'account_id',
  'payment_method_id',
  'payment_type',
  'external_id',
  'memo',
  'amount',
  'discount',
  'discount_sum',
  'payed_sum',
  'refund_sum',
  'start_date',
  'end_date',
  'created_at',
  'updated_at',
  'deleted_at',
  'refunded_at',
  'paid_at',
  'Currency.code',
  'Currency.id',
  'State.description',
  'State.name',
  'State.id',
  'Account.id',
  'Account.code',
  'Account.name',
  'PaymentMethod.token',
  'PaymentMethod.status',
  'PaymentMethod.access_type',
  'PaymentMethod.data',
  'PaymentMethod.id',
];
