import { useEffect, useState, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { Spin } from 'antd';
import { LoadingOutlined, FileImageOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import { ContentImgProps } from './types';
import { useImageIntersection } from './hooks/useImageIntersection';
import { APP_CONSTANS } from 'shared/constants';

const ContentImgComponent: React.FC<ContentImgProps> = ({
  currentContentUrl = null,
  previewUrl,
  updatedAt,
  id,
  replaceInModal = false,
  // sliderIndex = null,
  // arrIndex = null,
  isModal = false,
  isHigh = false,
  scaleFactor = null,
  scaleSign = null,
  setDisableCarousel = null,
}) => {
  const [width, setWidth] = useState<number | string>('');
  const [height, setHeight] = useState<number | string>('');
  const token = useSelector(
    (state: any) => state.auth.token,
    (prev, next) => prev === next
  );

  const antIcon = useMemo(
    () => <LoadingOutlined style={{ fontSize: 36 }} spin />,
    []
  );

  const { ref, inView, entry } = useImageIntersection();
  const {
    ref: imgRef,
    inView: imgInView,
    entry: entryImg,
  } = useImageIntersection();

  const { isFetching, isError, data, error, refetch, remove } = useQuery({
    queryKey: [
      APP_CONSTANS.PREVIEW_QUERY_KEY,
      previewUrl,
      id,
      updatedAt,
      isHigh,
    ],
    queryFn: async () => {
      const options = {
        headers: {
          cache: 'no-cache',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(previewUrl, options);
        const imgBlob = await res.blob();
        if (!imgBlob?.size) {
          return 'error';
        }
        data &&
          data !== 'error' &&
          data !== 'loader' &&
          URL.revokeObjectURL(data);
        const blobUrl = URL.createObjectURL(imgBlob);
        const objectUrlsSet = localStorage.getItem('objectUrls');
        const newObjectUrlsSet = new Set(
          objectUrlsSet ? JSON.parse(objectUrlsSet) : []
        );
        newObjectUrlsSet.add(blobUrl);
        localStorage.setItem(
          'objectUrls',
          JSON.stringify(Array.from(newObjectUrlsSet))
        );
        return blobUrl;
      } catch {
        return 'error';
      }
    },
    cacheTime: isModal ? 30000 : 300000,
    staleTime: isModal ? 30000 : 300000,
    enabled: !!previewUrl && inView,
  });

  useEffect(() => {
    if (replaceInModal) refetch();
  }, [refetch, replaceInModal]);

  useEffect(() => {
    return () => {
      if (isModal && data && data !== 'error' && data !== 'loader') {
        URL.revokeObjectURL(data);
        remove();
      }
    };
  }, [data, isModal, remove]);

  const zoomDimensions = useMemo(() => {
    if (!isModal || !imgInView || !entryImg?.target || !entry?.target) {
      return { width: '', height: '' };
    }

    const currentWidth = entryImg.target.clientWidth;
    const currentHeight = entryImg.target.clientHeight;

    if (scaleSign === 'plus' && scaleFactor) {
      return {
        width: currentWidth + currentWidth * 0.2,
        height: currentHeight + currentHeight * 0.2,
      };
    }

    if (scaleSign === 'minus' && currentWidth > 300) {
      return {
        width: currentWidth - currentWidth * 0.2,
        height: currentHeight - currentHeight * 0.2,
      };
    }

    return { width: '', height: '' };
  }, [isModal, imgInView, entryImg, entry, scaleFactor, scaleSign]);

  // Use in useEffect
  useEffect(() => {
    setWidth(zoomDimensions.width);
    setHeight(zoomDimensions.height);
  }, [zoomDimensions]);

  // Logic for Disable arrows when image isn't loaded yet
  useEffect(() => {
    if (isModal && currentContentUrl && currentContentUrl === previewUrl) {
      console.log('currentContentUrl ', currentContentUrl);
      if (isFetching && data !== 'error' && setDisableCarousel) {
        console.log('CONTENT LOADED');
        setDisableCarousel(false);
      } else if (isFetching && setDisableCarousel) {
        console.log('CONTENT LOADING...');
        setDisableCarousel(true);
      }
    }
  }, [
    isModal,
    data,
    setDisableCarousel,
    currentContentUrl,
    previewUrl,
    isFetching,
  ]);

  const imgStyles = useMemo(
    () => ({
      width: width ? `${width}px` : 'auto',
      height: height ? `${height}px` : 'auto',
      maxWidth: width ? `${width}px` : 'auto',
      maxHeight: height ? `${height}px` : 'auto',
    }),
    [width, height]
  );

  return (
    <div className={styles.imgBlock} key={`${id}${updatedAt}`} ref={ref}>
      {isFetching ? (
        <div className={clsx(styles.block, styles.loader)}>
          <Spin indicator={antIcon} />
        </div>
      ) : data === 'error' || isError || error ? (
        <div className={clsx(styles.block, styles.error)}>
          <FileImageOutlined />
          <p>Replacing failed</p>
        </div>
      ) : !isModal ? (
        <img
          key={`${id}${updatedAt}`}
          className={styles.img}
          style={{ backgroundImage: `url(${data})` }}
        />
      ) : (
        <img
          key={`${id}${updatedAt}`}
          className={clsx(
            styles.imgInModal,
            !isFetching && data !== 'error' ? styles.dBlock : styles.dNone
          )}
          ref={imgRef}
          src={data}
          style={imgStyles}
        />
      )}
    </div>
  );
};

export const ContentImg = memo(ContentImgComponent);
